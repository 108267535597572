module.exports = {
    seo: {
        title : "מאמר - מרעיון למוצר - Jenko",
        description:  ""
      },
  intro: {
      title: "מרעיון למוצר",
      desc: "איך תהפכו את הרעיון המבריק שלכם למוצר\u00A0ממשי\u00A0שמכניס\u00A0כסף?"
  },
  articleHeader: {
      desc: "אחד המאפיינים הבולטים של המאה ה-21 הוא החשיבה הרחבה שפורצת את כל המסגרות הקונבנציונאליות. היצירתיות המחשבתית שמשנה את פניהם של כל ענפי המשק, לאט אבל בטוח. אם יש לכם רעיון יצירתי שמצליח להביא משהו חדשני שלא חשבו עליו לפניכם, סביר מאד שיש לכם מכרה זהב ביד. זה נכון גם אם יש לכם רעיון לפטנט שיקל או יקצר תהליך קיים. אפילו אם אתם שולטים במיומנות מבוססת היטב ואתם משוכנעים שתצליחו לעשות משהו מוכר ושגור טוב יותר מאחרים, יש בהחלט עם מה לעבוד. אלא שבמבחן המציאות רוב הרעיונות הטובים נשארים במגירה. מחכים לצוץ מחדש במוחו של מי שיש לו לא רק את הרעיון אלא גם את הכלים ואת הדרך להוציא את מהות הרעיון מהכוח אל הפועל. זיקקנו עבורכם את אבני הדרך המשמעותיות קצר ולעניין. מוכנים?\u00A0יוצאים\u00A0לדרך!"
  },
  secP: {
      title: "איך לפתח מוצר?",
      p: [
          'יש לעיתים נטייה לחשוב שהדרך להפוך רעיון למוצר משתנה מהותית בין ענף לענף. היא אכן מסתעפת בשלב הפיתוח והייצור אך התוואי המהותי שלה נותר זהה כמעט לגמרי בשלבים הראשוניים של הדרך. בין אם אנחנו מדברים על רעיונות למוצרי פרסום, רעיונות למוצרי דיגיטל, רעיון עבור שירות ולעומתו רעיון למוצר שמשלב בו מראה וחזות פיזיים ממשיים, כולם מתחילים באותו המקום בדיוק: סיעור מוחות. שלב מהותי וסופר אפקטיבי אם אתם מחפשים רעיונות למוצר שלא קיים עדיין אבל\u00A0לא\u00A0רק.',
          'סיעור מוחות הוא שלב החילוץ: מקונספט בלתי מגובש בראש שלכם לחומרי גלם ממשיים איתם אפשר להתחיל לעבוד. כוחו של סיעור מוחין הוא לא רק בהצפת המידע, מיפויו ותיעודו אלא מעל הכל במשתתפים שמגויסים אליו. אין עוררין על זה שהשלם גדול יותר מסך חלקיו והעיקרון הזה בא לידי ביטוי היטב בסיעור מוחות שמבוצע טוב. קל וחומר כזה שמתבצע על ידי אנשי מקצוע מנוסים שמכירים את התהליך לאורכו ולרוחבו ומבצעים אותו ברמה יומיומית לאורך\u00A0שנים.'
      ]
  },
  thrdP: {
      title: "אפיון ובדיקת התכנות",
      p: [
          'לאחר שבחרנו רעיון ועיבדנו אותו מקונספט כללי לכדי רעיון אחיד ומגובש, אפשר להתקדם לשלב המהותי הבא: שלב האפיון ובדיקת\u00A0ההיתכנות.',
          'אפיון הוא השלב שבו הרעיון קורם עור וגידים. כאן אנחנו ממפים את הרעיון ברמת הזהות שלו, ברמת התפעול שלו וברמת החזון העסקי שלו. בשלב האפיון אנו למעשה מעניקים חיים למוצר שלנו, מקונספט רעיוני לישות ממשית. במהלך העבודה נוגעים באלמנטים כגון מיפוי נקודות חוזקה וחולשה אל מול המתחרים, ביאור ותעדוף של קהל היעד שלנו, מיקוד אפיקי העבודה שאנו מעוניינים ללכת בהם ועוד. אם לסכם במשפט: שלב האפיון מגבש את הDNA של המוצר שלנו לכדי זהות\u00A0ברורה\u00A0ומקיפה.'
      ]
  },
  frthP: {
      p: 'בדיקת היתכנות היא שלב אקוטי שיכול להיות ההבדל שבין עסק שנסגר או גרוע מכך, עסק שנסגר בליווי הפסדים כבדים וחובות, לבין עסק מצליח שנוסק אל-על באופן חלק ויציב. כאן אנחנו נוגעים הן באסטרטגיה והן בטקטיקה. אנשי מקצוע מיומנים יודעים לשאול את השאלות הנכונות ובאמתחתם כלי ניתוח חדשניים ויעילים שיודעים לפצח כדאיות ולשפוך אור בהיר לגבי אופקו הצפוי של מוצר שנולד. בבדיקת ההיתכנות נגע בפרמטרים כגון: חקר מתחרים, אפיון קהל היעד ואופיו, מהו כוח הקנייה שלהם? מה טווח התמחור הכדאי ביחס לכוח הקנייה הזה תוך שקלול עלויות ייצור פיתוח? היצע לעומת ביקוש, נתח הרווחים הצפוי בשלבים השונים של הדרך ובקיצור מחקר מקצועי שיסלול עבורכם את הדרך הבטוחה ביותר להצלחה. בשלב הזה כבר כדאי לחשוב באופן מעשי על רישום פטנט. צעד קריטי בחשיבותו שאם לא תתנו עליו את הדעת בזמן, יכול לטרפד את כל המסע כולו. בעלות על הקניין הרוחני שעומד בלב הרעיון היא ערובה הכרחית להוצאתו\u00A0לפועל.'
  },
  ffthP: {
      title: "פיתוח וייצור",
      p: 'בחרנו רעיון וגיבשנו לו זהות, אספנו, בדקנו, השווינו וניתחנו, מה הלאה? מכאן נבחר את האופציה הכדאית ביותר לפיתוח המוצר בהתאם לנותנים שגיבשנו בבדיקת ההיתכנות ונגש לבניית אבטיפוס. האבטיפוס הוא האמא והאבא של המוצר. הוא הגרסה הראשונית שתתווה את הדרך לעבר הגרסאות הבאות שילכו ויתפתחו במרוצת הזמן והשימוש. בשלב הזה נפתח את המוצר באמצעות הטכנולוגיות החדשניות והמתקדמות ביותר שעומדות לרשותנו ונחקור אותו על כל התרחישים ותחת כל התנאים האפשריים. המומחיות שלנו היא להוציא אתכם עם אבטיפוס יציב וחזק שמוכן להשקה אל מול משקיעים ולייצור המוני שיפיץ את הבשורה שלכם\u00A0בעולם.'
  },
  sixP: {
      title: "עולים למגרש",
      p: [
          'וכך, תוך כמה שלבים אמנם לא כל כך פשוטים אבל ברורים לגמרי, אתם עם גרסה ראשונית מוגמרת של המוצר שלכם ביד מוכנים לעלות למגרש. מרגש,\u00A0לא? ',
          'בשלב הזה מתמודדים עם אתגרים כמו גיוס משקיע או משקיעים, מימון המונים או כל סוג אחר של גיוס משאבים פיננסיים שזמין לנו במשק. אנחנו נסייע לכם להגיע לרגע הזה בטוחים ומוכנים. יצירת מצגת מרעיון למוצר, יצירת תכנית עסקית מרשימה ושאר הכלים שצריכים להיות בארגז הכלים שלכם על מנת לסחוף ולכבוש את המשקיעים שירגישו ברי מזל שזכו להשקיע בכם. השלב הזה נוגע גם בייצור המוני. אנחנו מפנים אתכם לתעשיינים הרלוונטיים והכדאיים ביותר עבורכם תוך ניהול ובקרה מלאים של תהליך הייצור משלב ההפניה ועד הרגע שיש לכם ביד ליין של מוצרים מוכנים להשקה\u00A0מכירה\u00A0ושיווק.'
      ]
  },
  concl: {
      title: "סיכום",
      p: 'הדרך של פיתוח רעיון לכדי מוצר היא לא דרך פשוטה אבל כל דרך נהיית קלה יותר כשיש מפה ברורה שמורה כיצד להגיע ליעד. אנחנו כאן כדי ללוות אתכם מהצעדים הראשונים של בחירת הרעיון וגיבושו, אפיון, בדיקת היתכנות, רישום פטנט, בניית אבטיפוס מקצועי, פיתוח שלו, מימון וייצור. נלווה אתכם יד ביד בדרך המקצועית הנעימה והבטוחה שהלכנו בה כבר כל כך הרבה פעמים עם לקוחותינו שממוצריהם נהנים קהלים\u00A0רבים\u00A0כיום.'
  },
  dir: 'rtl'
};