import * as React from "react";
import Layout from "../../../layouts/he";
import PageHead from "../../../components/shared/PageHead";
import Intro from "../../../components/shared/Intro";
import * as styles from "../../../styles/pages/articles/index.module.css";
import content from "../../../data/content/articles/idea-to-product/article-he";
import brightmind from "../../../assets/images/articles/brightmind.svg";
import checklist from "../../../assets/images/articles/checklist.png";
import devprod from "../../../assets/images/articles/devprod.svg";

const IdeaTopProduct = (props) => {
  const {
    sIntroSection,
    sIntro,
    sArticleContainer,
    sIP1Sec,
    sIP2Sec,
    sIP3Sec,
    sIP4Sec,
    sIP5Sec,
    sIP6Sec,
    sIP7Sec,
  } = styles;
  const {
    seo,
    intro,
    articleHeader,
    secP,
    thrdP,
    frthP,
    ffthP,
    sixP,
    concl,
    dir,
  } = content;
  return (
    <Layout location={props.location} lang="he">
      <PageHead pageHeadData={seo} />
      <article lang="he" className="article" dir={dir}>
        <Intro content={intro} styles={{ sIntroSection, sIntro }} />
        <div className={sArticleContainer}>
          <section className={sIP1Sec}>
            <p>{articleHeader.desc}</p>
            <span></span>
          </section>
          <section className={sIP2Sec}>
            <div>
              <h2>{secP.title}</h2>
            </div>
            <div>
              <p>{secP.p[0]}</p>
              <img src={brightmind} alt="brightmind" />
              <p>{secP.p[1]}</p>
            </div>
          </section>
          <section className={sIP3Sec}>
            <div>
              <img src={checklist} alt="checklist" />
            </div>
            <div>
              <h2>{thrdP.title}</h2>
              {thrdP.p.map((p, index) => (
                <p key={index}>{p}</p>
              ))}
            </div>
          </section>
          <section className={sIP4Sec}>
            <div>
              <p>{frthP.p}</p>
            </div>
            <div></div>
          </section>
          <section className={sIP5Sec}>
            <div>
              <h2> {ffthP.title}</h2>
              <p>{ffthP.p}</p>
            </div>
            <div>
              <img src={devprod} alt="devprod" />
            </div>
          </section>
          <section className={sIP6Sec}>
            <div>
              {sixP.p.map((p, index) => (
                <p key={index}>{p}</p>
              ))}
            </div>
            <div>
              <h2> {sixP.title}</h2>
            </div>
          </section>
          <section className={sIP7Sec}>
            <h2> {concl.title}</h2>
            <p>{concl.p}</p>
          </section>
        </div>
      </article>
    </Layout>
  );
};

export default IdeaTopProduct;
